// src/index.scss
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

$primary-color: #7737E3;

$small: 768px;
$medium: 992px;

.grayscale {
  filter: grayscale(100%);

  img {
    height: 40px;
  }
}

.text {

  &.primary {
    color: $primary-color;
  }
}

body {
  background-color: #fff;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: black;

  @media screen and (max-width: $small) {
    line-height: 1.8;
  }
}

h1 {}

h2 {
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  margin-bottom: 3rem;

  @media screen and (max-width: $small) {
    font-size: 24px;
  }
}

.btn {

  &.btn-main {
    position: relative;
    background: #7737E3;
    color: white;
    border-radius: 30px;
    padding: 0.6rem 1.5rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 275px;

    i {
      position: absolute;
      display: block;
      background: white;
      border-radius: 50%;
      padding: 2px;
      top: 50%;
      right: 0.6rem;
      margin-top: -14px;

      img {
        display: block;
      }
    }
  }

  &.btn-white {
    font-weight: 600;
    background-color: white;
    border-radius: 40px;
    padding: 0.6rem 1.5rem;
    width: 275px;
    border: 1px solid #C2C2C2;
  }
}

header {
  max-width: 800px !important;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 40px;
  top: 20px;
  padding: 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  z-index: 10;

  @media screen and (max-width: $medium) {
    background-color: white;
  }

  @media screen and (max-width: $small) {
    width: 95%;
  }

  nav {
    background: none;

    .navbar-brand {
      color: #7737E3;
      font-weight: 700;
      padding: 0;
      font-size: 16px;

      img {
        height: 40px;
        margin-right: 0.5rem;
      }
    }

    .navbar-collapse {
      justify-content: flex-end;

      @media screen and (max-width: $medium) {
        gap: 1rem;
        height: auto;
        overflow: hidden;
        max-height: 0;
        transition: 1s;
      }
    }

    .navbar-collapse.open {
      @media screen and (max-width: $medium) {
        gap: 1rem;
        max-height: 300px;
      }
    }

    .navbar-nav {
      gap: 10px;

      @media screen and (max-width: $medium) {
        gap: 1rem;
      }
    }

    .nav-link {
      font-size: 14px;
      padding: 0;
      font-weight: 700;

      &.btn {
        display: inline-block;
        width: auto;
        font-weight: 700;
        padding: 10px 15px !important;
      }
    }
  }
}

section {
  padding-top: 5rem;
  padding-bottom: 5rem;
  padding-left: 2rem;
  padding-right: 2rem;

  @media screen and (max-width: $small) {
    padding: 3rem 0.5rem;
  }
}

.bg-hero {
  background: url('../assets/hero.png');
  background-size: cover;
  background-position: center;
  padding-top: 6rem;

  h1 {
    max-width: 800px;
    margin: 0 auto;
    font-weight: 800;
    line-height: 1.3;
    font-size: 3rem;

    @media screen and (max-width: $medium) {
      font-size: 2rem;
    }

    img {
      display: inline-block;
      width: 60px;
      margin-top: -15px;
      border-radius: 50%;
      box-shadow: 0 15px 15px rgba(119,55,27,0.2);
    }

    span {
      background: linear-gradient(to right, #7737E3, #B040DD);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }
  }

  p {
    text-align: center;
    font-weight: 300;
    margin: 0 auto 3rem;
    max-width: 800px;

    @media screen and (max-width: $medium) {
      line-height: 1.8;
    }
  }
}

.bg-circle {
  background-image: url('../assets/hero-img.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;

  @media screen and (max-width: $medium) {
    background: none;
  }
}

.btn-hero {

  @media screen and (max-width: $small) {
    flex-direction: column;
    gap: 1.5rem !important;
  }

  .btn {

    @media screen and (max-width: $small) {
      margin: 0 auto;
    }
  }
}

.testi {
  display: flex;
  justify-content: center;

  img {
    display: block;
    height: 64px;
    margin-right: 1rem;
  }

  .rate-in {

    img {
      display: block;
      height: 18px;
      margin-bottom: 0.7rem;
      margin-right: 0;
    }

    span {

      span {
        font-weight: 700;
      }
    }
  }
}

.feature {
  padding-top: 5rem;

  .container {
    flex-wrap: wrap;
    gap: 1rem !important;

    .row {
      --bs-gutter-x: 3rem;
      --bs-gutter-y: 2rem;

      > div {

        .feature--card {
          padding: 24px;
          background: url('../assets/bg-card.jpg');
          background-size: cover;
          background-position: center;
          position: relative;
          overflow: hidden;
      
          border-radius: 24px;
          text-align: left;
          height: 100%;
      
          h3 {
            position: relative;
            font-size: 20px;
            line-height: 1.5;
            font-weight: 400;
            z-index: 1;
          }
      
          p {
            text-align: left;
            margin: 0;
            font-size: 14px;
            font-weight: 300;
          }
        }

        &:first-child .feature--card:before {
          content: "";
          position: absolute;
          background-color: rgba(255, 255, 255, 0.6);
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          z-index: 0;
        }
    
        &:nth-child(2) .feature--card {
          background: #FEF0E4;
        }
    
        &:nth-child(3) .feature--card {
          background: #F5F5F5;
        }
    
        &:nth-child(4) .feature--card {
          background: #F4F1F6;
        }
      }
    }
  }
}

.pricing {
  max-width: 780px;
  margin: 0 auto;

  table {
    border-collapse: separate;
    border-spacing: 0;
    margin: 0 auto;
    font-size: 14px;
    border-radius: 12px;

    @media screen and (max-width: $small) {
      font-size: 12px;
    }

    tbody {

      tr {

        &:nth-child(odd) {
          background-color: #FAFAFA;
        }

        &:first-child {

          td {
            vertical-align: middle;
          }

          td:last-child {
            border-top: 2px solid #7737E3;
            background-color: #7737E3;
            font-weight: 700;

            img {
              width: 30px;
              margin-right: 0.5rem;
            }
          }
        }

        .no-border {
          border: none;
        }

        .rounded-tl-lg {
          border-top-left-radius: 15px;
        }

        .rounded-tr-lg {
          border-top-right-radius: 15px;
        }

        .rounded-bl-lg {
          border-bottom-left-radius: 15px;
        }

        .rounded-br-lg {
          border-bottom-right-radius: 15px;
        }

        th {
          padding: 1rem;
          vertical-align: top;
          border-left: 1px solid #dee2e6;
          border-right: 1px solid #dee2e6;
          border-bottom: 1px solid #dee2e6;

          @media screen and (max-width: $small) {
            display: none;
          }
        }

        td {
          padding: 1rem;
          vertical-align: top;
          border-bottom: 1px solid #dee2e6;

          @media screen and (max-width: $small) {
            border-left: 1px solid #dee2e6;
            line-height: 1.8;
          }

          &:last-child {
            border-left: 2px solid #7737E3;
            border-right: 2px solid #7737E3;
          }
        }
      }
    }
  }
}

.step-box {
  position: relative;

  @media screen and (max-width: $small) {
    flex-direction: column;
  }

  &:before {
    content: "";
    position: absolute;
    width: 70%;
    height: 5px;
    top: 40%;
    left: 50%;
    background-color: #884AE9;
    transform: translateX(-50%);
    z-index: 0;

    @media screen and (max-width: $small) {
      height: 70%;
      width: 5px;
      transform: translateY(-50%);
    }
  }

  &>div {
    position: relative;
    background-color: white;
    padding: 2rem 1rem;
    max-width: 360px;
    border-radius: 16px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
    z-index: 1;

    @media screen and (max-width: $small) {
      margin: 0 auto;
    }

    img {
      margin-bottom: 2rem;
      max-width: 160px;
    }

    h3 {
      font-size: 20px;
      font-weight: 700;

      @media screen and (max-width: $small) {
        line-height: 1.8;
        margin-bottom: 1rem !important;
      }
    }

    p {
      font-size: 14px;
      margin-bottom: 0;
    }
  }
}

.accordion {
  max-width: 780px;
  margin: 0 auto;

  .accordion-item {
    margin-bottom: 1rem;
    background-color: #ededed;
  }

  .accordion-button {
    background-color: #ededed;
    font-weight: 700;
    line-height: 1.8;
  }
}

.cta-section {
  position: relative;
  background: #FAFAFA;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url('../assets/mask.png');
    background-size: contain;
    background-position: center bottom;
    background-repeat: no-repeat;
  }

  &>div {
    position: relative;
    z-index: 1;
  }

  h2 {

    @media screen and (max-width: $medium) {
      font-size: 24px;
    }

    @media screen and (max-width: $small) {
      font-size: 22px;
    }

    img {

      @media screen and (max-width: $medium) {
        width: 80px;
      }

      @media screen and (max-width: $small) {
        width: 60px;
      }
    }
  }
}

footer {

  .text-start {
    width: 50%;
  }

  p {
    font-size: 12px;
  }

  small {
    font-size: 12px;
  }
}

.foot--link {

  &>div {
    gap: 60px;
    margin-bottom: 4rem;

    a {
      color: #2A3F56;
      font-size: 14px;
      font-weight: 400;
      text-decoration: none;
    }

    &:last-child {
      gap: 10px;
      margin-bottom: 0;

      a {}
    }
  }
}

.socmed {

  a {
    width: 32px;

    img {
      display: block;
      max-width: 100%;
    }
  }
}

form {

  .form-label {
    font-size: 14px;
    font-weight: 600;
  }

  .form-check {
    margin-bottom: 0.5rem;
  }

  .form-check-label {
    font-size: 14px;
  }

  .form-control {
    border-color: #bbb;
    font-size: 14px;
  }

  .form-select {
    border-color: #bbb;
    font-size: 14px;
  }

  .form-check-input {
    border-color: #bbb;
  }
}

.response {

  @media screen and (max-width: $small) {
    padding: 1rem 0;
  }

  h1 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 1rem;

    @media screen and (max-width: $small) {
      font-size: 1.5rem;
    }
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 1rem;

    @media screen and (max-width: $small) {
      font-size: 1.2rem;
    }
  }

  h3 {
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 1rem;

    @media screen and (max-width: $small) {
      font-size: 1rem;
    }
  }

  p {}

  ol,
  ul {
    margin-bottom: 1rem;

    li {
      margin-bottom: .5rem;
    }
  }
}

.modal {
  background-color: rgba(255, 255, 255, 0.8);
}

.exclusive-invitation {
  padding-top: 10px;
  border-top: solid 1px #ccc;

  p {
    text-align: center;
    font-style: italic;
    font-weight: 700;
  }

  .btn {
    margin: 0 auto 10px;
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0.25;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}

.fade-animation {
  animation: fadeInOut 3s infinite;
}